import type { INavMenuSection } from './sidebar.types'

enum DEVICES {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

export const ROUTES: INavMenuSection[] = [
  {
    title: '',
    whitelistedDevices: [DEVICES.DESKTOP, DEVICES.MOBILE],
    whitelistEnvironment: ['localhost', 'development', 'staging', 'production', 'demo'],
    routes: [
      {
        active: true,
        icon: 'DashboardIcon',
        title: 'Dashboard',
        match: 'Dashboard',
        path: '/dashboard',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistedDevices: [DEVICES.DESKTOP, DEVICES.MOBILE],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      }
    ]
  },
  {
    title: 'Payments',
    icon: 'NewTransferIcon',
    whitelistedDevices: [DEVICES.DESKTOP, DEVICES.MOBILE],
    whitelistEnvironment: ['localhost', 'development', 'staging', 'production'],
    whitelistOrganizations: ['c768993a-ddec-49c7-b1d3-d3813897e761', 'def2920b-76a7-4395-82dd-e4776e1a7836'],
    routes: [
      {
        active: true,
        icon: 'NewTransferIcon',
        title: 'Payouts',
        match: 'Payouts',
        path: '',
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production'],
        children: [
          {
            active: true,
            icon: 'NewTransferIcon',
            title: 'Drafts',
            path: '/transact/drafts',
            match: 'Transfer',
            description:
              'Overview of your balances, transaction records requiring your attention and other quick actions',
            blacklistRole: ['Employee'],
            whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
          },
          {
            active: true,
            icon: 'NewTransferIcon',
            title: 'Transfer',
            path: '/transfer',
            match: 'Transfer',
            description:
              'Overview of your balances, transaction records requiring your attention and other quick actions',
            blacklistRole: ['Employee'],
            whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
          },
          {
            active: true,
            icon: 'NewTransferIcon',
            title: 'History',
            path: '/transact/payment-history',
            match: 'Transfer',
            description:
              'Overview of your balances, transaction records requiring your attention and other quick actions',
            blacklistRole: ['Employee'],
            whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
          },
          {
            active: true,
            icon: 'PendingApprovalIcon',
            title: 'Queue',
            match: 'Pending Approval',
            path: '/pendingApproval',
            description: 'Pending Approval for your transactions',
            blacklistRole: ['Employee'],
            whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
          }
        ]
      },
      {
        active: true,
        icon: 'CardIcon',
        title: 'Cards',
        match: 'Cards',
        path: '/cards',
        description: 'Manage your HQ cards',
        whitelistedDevices: [DEVICES.DESKTOP, DEVICES.MOBILE],
        whitelistEnvironment: ['localhost', 'staging'],
        whitelistOrganizations: [
          '0b350ca6-630f-45b2-bd3b-95642b917596',
          'c768993a-ddec-49c7-b1d3-d3813897e761',
          'def2920b-76a7-4395-82dd-e4776e1a7836'
        ]
      },
      {
        active: true,
        icon: 'InvoiceIcon',
        title: 'Invoices',
        match: 'Invoices',
        path: '/invoices',
        description: 'Invoices',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production', 'demo']
      },
      {
        active: true,
        icon: 'TransactionIcon',
        title: 'Swap',
        match: 'Swap',
        path: '/swap',
        description: 'Swap',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production', 'demo']
      },
      {
        active: true,
        icon: 'RecievePayment',
        title: 'Payment Links',
        path: '/payme',
        match: 'Receive Payment',
        blacklistRole: ['Employee'],
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions'
      }
    ]
  },
  {
    title: 'Reporting',
    whitelistEnvironment: ['localhost', 'development', 'staging', 'production', 'demo'],
    routes: [
      {
        active: true,
        icon: 'TransactionIcon',
        title: 'Transactions',
        match: 'Transaction Records',
        path: '/transactions',
        description: 'Overview of yourbalances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      },
      {
        active: true,
        icon: 'SourceIcon',
        title: 'Wallets',
        match: 'Wallets',
        path: '/wallets',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      },
      {
        active: true,
        icon: 'AssetIcon',
        title: 'Tokens',
        match: 'Assets',
        path: '/assets',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      },
      {
        active: true,
        icon: 'CategoryIcon',
        title: 'Categories',
        match: 'Categories',
        path: '/categories',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: []
      },
      {
        active: true,
        icon: 'NFTIcon',
        title: 'NFTs',
        match: 'NFTs',
        path: '/nfts',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production', 'demo']
      },
      {
        active: true,
        icon: 'CategoryIcon',
        title: 'Chart of Accounts',
        match: 'Chart of Accounts',
        path: '/chart-of-accounts',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      }
    ]
  },
  {
    title: 'More',
    whitelistEnvironment: ['localhost', 'development', 'staging', 'production', 'demo'],
    routes: [
      {
        active: true,
        icon: 'ContactIcon',
        title: 'Address Book',
        match: 'Contacts',
        path: '/contacts',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      },
      {
        active: true,
        icon: 'MembersIcon',
        title: 'Members',
        match: 'Members',
        path: '/members',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      },
      {
        active: true,
        icon: 'IntegrationIcon',
        title: 'Integrations',
        match: 'Integrations',
        path: '/integrations',
        description: 'Overview of your integrations',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      },
      {
        active: true,
        icon: 'SettingIcon',
        title: 'Settings',
        match: 'Org Settings',
        path: '/orgsettings',
        description: 'Overview of your balances, transaction records requiring your attention and other quick actions',
        blacklistRole: ['Employee'],
        whitelistEnvironment: ['localhost', 'development', 'staging', 'production']
      }
    ]
  }
]
