import { ActionIcon, Button, createTheme, Select, Text, TextInput, Notification, Modal } from '@mantine/core'
import classes from './mantine.module.css'
import modalClasses from './custom-mantine/modal.module.css'
import inputClasses from './custom-mantine/input.module.css'
import fieldClasses from './custom-mantine/field-inputs.module.css'

export const mantineTheme = createTheme({
  primaryColor: 'primary',
  primaryShade: 8,
  fontFamily: 'Inter, sans-serif',
  breakpoints: {
    xs: '32em',
    sm: '40em',
    md: '48em',
    lg: '64em',
    xl: '80em',
    '2xl': '96em'
  },
  headings: {
    fontWeight: '700',
    fontFamily: 'Epilogue, sans-serif',
    sizes: {
      // heading1
      h1: {
        fontSize: '2rem',
        lineHeight: '2.5rem'
      },
      // heading2
      h2: {
        fontSize: '1.5rem',
        lineHeight: '2rem'
      },
      // heading3
      h3: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem'
      },
      // subtitle1
      h4: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: '600'
      },
      // subtitle2
      h5: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '600'
      }
    }
  },

  components: {
    Text: Text.extend({
      defaultProps: {
        size: 'md',
        fw: '400'
      },
      styles: (theme, props) => ({
        root: {
          // overline
          ...(props.size === 'xs' && {
            fontSize: '0.625rem',
            lineHeight: '0.875rem'
          }),
          // caption
          ...(props.size === 'sm' && {
            fontSize: '0.75rem',
            lineHeight: '1rem'
          }),
          // body2
          ...(props.size === 'md' && {
            fontSize: '0.875rem',
            lineHeight: '1.125rem'
          }),
          // body1
          ...(props.size === 'lg' && {
            fontSize: '1rem',
            lineHeight: '1.25rem'
          }),
          ...(props.size === 'xl' && {
            fontSize: '1.25rem',
            lineHeight: '1.75rem'
          })
        }
      })
    }),
    Button: Button.extend({
      styles: {
        root: {
          fontSize: '14px',
          fontWeight: 400,
          '--button-hover': '#00006B'
        }
      },
      vars: (theme, props) => {
        if (props.variant === 'light') {
          return {
            root: {
              '--button-bg': '#F2F4F7',
              '--button-hover': 'rgb(229 229 229)'
            }
          }
        }
        if (props.color === 'secondary') {
          return {
            root: {
              '--button-bg': '#F1F1EF',
              '--button-hover': 'rgba(217, 217, 215, 1)',
              '--button-color': '#2D2D2C'
            }
          }
        }
        return {
          root: {}
        }
      },
      classNames: { root: 'disabled:opacity-70' }
    }),
    Notification: Notification.extend({
      // TODO - More styles added in globals.css
      defaultProps: {
        variant: 'success'
      },

      // TODO - Is there not a way to do this with CSS ??
      styles: (theme, props) => ({
        closeButton: {
          ...(props.variant === 'success' && {
            backgroundColor: '#0BA740'
          }),
          ...(props.variant === 'error' && {
            backgroundColor: '#D14545'
          }),
          color: 'white'
        },
        root: {
          minHeight: 60,
          ...(props.variant === 'success' && {
            color: 'white',
            backgroundColor: '#0BA740'
          }),
          ...(props.variant === 'error' && {
            color: 'white',
            backgroundColor: '#D14545'
          })
        },
        title: {
          ...(props.variant === 'success' && {
            color: 'white',
            fontWeight: 600,
            fontSize: 14
          }),
          ...(props.variant === 'error' && {
            color: 'white',
            fontWeight: 600,
            fontSize: 14
          })
        },
        description: {
          fontSize: 16,
          fontWeight: 500,

          ...(props.variant === 'success' && {
            color: 'white'
          }),
          ...(props.variant === 'error' && {
            color: 'white'
          })
        }
      })
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'md'
      },
      classNames: (theme, props, ctx) => ({
        label: fieldClasses.label,
        input: fieldClasses.input,
        error: fieldClasses.error,
        required: fieldClasses.required
      })
    }),
    Select: Select.extend({
      defaultProps: {
        checkIconPosition: 'right',
        searchable: true,
        pointer: true,
        maxDropdownHeight: 200,
        comboboxProps: { shadow: 'md' }
      },
      classNames: (theme, props, ctx) => ({
        label: fieldClasses.label,
        input: fieldClasses.input,
        required: fieldClasses.required,
        error: fieldClasses.error,
        option: fieldClasses.option,
        empty: fieldClasses.dropdownEmpty
      })
    }),
    Modal: Modal.extend({
      classNames: {
        root: modalClasses.root,
        content: modalClasses.content,
        overlay: modalClasses.overlay,
        close: modalClasses.close,
        title: modalClasses.title,
        header: modalClasses.header
      }
    }),
    ActionIcon: ActionIcon.extend({
      defaultProps: {
        size: 'md',
        variant: 'default'
      }
    })
  },
  colors: {
    primary: [
      '#0000D1',
      '#0000BC',
      '#000093',
      '#00006B',
      '#000042',
      '#000019',
      '#000000',
      '#000000',
      '#000000',
      '#000000'
    ],
    success: [
      '#e7fff0',
      '#d2fce1',
      '#a5f8c0',
      '#73f49e',
      '#4df281',
      '#36f06f',
      '#29ef64',
      '#1cd553',
      '#0cbd48',
      '#00a43a'
    ],
    error: [
      '#ffeaea',
      '#fdd3d3',
      '#f5a5a5',
      '#ef7575',
      '#e94c4b',
      '#e63230',
      '#e52423',
      '#cc1717',
      '#b60f12',
      '#a0000c'
    ]
    // warning: [
    //   '#fff2e3',
    //   '#ffe5ce',
    //   '#facba0',
    //   '#f6ae6d',
    //   '#f39542',
    //   '#f18627',
    //   '#f17e17',
    //   '#d76c0a',
    //   '#c05f03',
    //   '#a85000'
    // ],
    // info: [
    //   '#ebf8ff',
    //   '#d6edfa',
    //   '#a7daf8',
    //   '#77c7f6',
    //   '#55b6f5',
    //   '#44abf5',
    //   '#3ba6f6',
    //   '#2f91db',
    //   '#2281c4',
    //   '#006fad'
    // ],
    // secondary: [
    //   '#fef2f5',
    //   '#eae6e7',
    //   '#cdcdcd',
    //   '#b2b2b2',
    //   '#9a9a9a',
    //   '#8b8b8b',
    //   '#848484',
    //   '#717171',
    //   '#676465',
    //   '#5e5457'
    // ]
  }
})
